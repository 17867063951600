<!--
 * @Description:我的 个人信息 selfInfo
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-04 14:47:11
 * @LastEditors: linf
-->
<template>
  <div>
    <van-nav-bar title="个人信息"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="top">
      <van-uploader :after-read="uploadAvatar">
        <div class="img-box"
             type="primary">
          <van-image class="head"
                     fit="cover"
                     style="width:80px;height:80px;border-radius: 50%;overflow: hidden;"
                     width="80px"
                     height="80px"
                     :src="userInfo.avatar? $headPhotoUrl+userInfo.avatar : require('@/assets/mySelf/head.png')" />
          <van-image class="camera"
                     :src="require('@/assets/mySelf/camera.png')" />
        </div>
      </van-uploader>
    </van-row>

    <van-row class="dt-secondLine">
      <!-- 昵称 -->
      <van-row class="dt-line">
        <van-col :span="10">昵称</van-col>
        <van-col :span="13"
                 @click="updateUserInfo('userName')"
                 class="dt-contc">{{userInfo.userName}}</van-col>
        <van-col :span="1"
                 @click="updateUserInfo('userName')">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <van-row class="dt-cut"></van-row>
      <!-- 手机号 -->
      <van-row class="dt-line">
        <van-col :span="10">手机号</van-col>
        <van-col :span="13"
                 @click="handleClickChangePhone()"
                 class="dt-contc">{{userInfo.phoneNumber}}</van-col>
        <van-col :span="1"
                 @click="handleClickChangePhone()">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <van-row class="dt-cut"></van-row>
      <!-- 邮箱 -->
      <van-row class="dt-line">
        <van-col :span="10">邮箱</van-col>
        <van-col :span="13"
                 @click="updateUserInfo('eMail')"
                 class="dt-contc">{{userInfo.eMail}}</van-col>
        <van-col :span="1"
                 @click="updateUserInfo('eMail')">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <van-row class="dt-cut"></van-row>
      <!-- 实名认证 -->
      <van-row class="dt-line"
               v-show="state===0">
        <van-col :span="10">实名认证</van-col>
        <van-col @click="handleAttestation(val=state)"
                 :span="13"
                 class="dt-contc">{{commit}}</van-col>
        <van-col :span="1"
                 @click="handleAttestation(val=state)">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!--实名认证-->
      <van-row class="dt-line"
               v-show="state===1">
        <van-col :span="10">实名认证</van-col>
        <van-col :span="13"
                 @click="handleAttestation(val=state)"
                 class="dt-contc">
          <div class="dt-attestation">
            <div class="dt-name">王珊珊</div>
            <div class="dt-attestation-box">
              <van-image class="dt-beijng"
                         :src="require('@/assets/mySelf/beijing.png')" />
              <van-image class="dt-dunpai"
                         :src="require('@/assets/mySelf/dunpai.png')" />
              <div class="dt-text">已认证</div>
            </div>
          </div>
        </van-col>
        <van-col :span="1"
                 @click="handleAttestation(val=state)">
          <img src="@/assets/mySelf/right.png"
               class="img"
               alt="">
        </van-col>
      </van-row>
      <!-- 性别 -->
      <van-row class="dt-thirdLine">
        <van-row class="dt-line">
          <van-col :span="10">性别</van-col>
          <van-col :span="13"
                   @click="updateUserInfo('userSex')"
                   class="dt-contc">{{userInfo.userSex}}</van-col>
          <van-col :span="1"
                   @click="updateUserInfo('userSex')">
            <img src="@/assets/mySelf/right.png"
                 class="img"
                 alt="">
          </van-col>
        </van-row>
        <van-row class="dt-cut"></van-row>
        <!-- 生日 -->
        <van-row class="dt-line">
          <van-col :span="10">生日</van-col>
          <van-col :span="13"
                   @click="updateUserInfo('userAgeTime')"
                   class="dt-contc">{{userInfo.userAgeTime}}</van-col>
          <van-col :span="1"
                   @click="updateUserInfo('userAgeTime')">
            <img src="@/assets/mySelf/right.png"
                 class="img"
                 alt="">
          </van-col>
        </van-row>
      </van-row>
      <div class="reLogin-box">
        <div class="reLogin"
             @click="reLogin">退出登录</div>
      </div>
    </van-row>
    <van-popup v-model="showUpdate"
               position="right"
               :style="{ height: '100%',width:'100%' }">
      <van-nav-bar :title="'修改'+infoObj[updateTextKey]"
                   left-text="返回"
                   left-arrow
                   right-text="保存"
                   @click-right="updateInfoConfirm"
                   @click-left="showUpdate=false;updateData=''" />
      <van-field style="width: 96%;margin-left: 2%;margin-bottom:50px;border-bottom: 1px #19A9FC solid;"
                 v-model="updateData"
                 v-if="['userName','phoneNumber','eMail','userAgeTime'].includes(updateTextKey)"
                 :placeholder="infoObj[updateTextKey]" />
      <van-radio-group v-if="updateTextKey==='userSex'"
                       v-model="updateData">
        <van-cell-group>
          <van-cell title="男"
                    clickable
                    @click="updateData = '男'">
            <template #right-icon>
              <van-radio name="男" />
            </template>
          </van-cell>
          <van-cell title="女"
                    clickable
                    @click="updateData = '女'">
            <template #right-icon>
              <van-radio name="女" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-datetime-picker v-if="updateTextKey==='userAgeTime'"
                           v-model="userAgeTime"
                           type="date"
                           title="选择年月日"
                           :show-toolbar="false"
                           :min-date="minDate"
                           :max-date="maxDate"
                           @change="updateData = $fmtDate(userAgeTime,'yyyy-MM-dd')" />
    </van-popup>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'
import Compressor from 'compressorjs'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      userCode: '',
      userInfo: {},
      state: 0,
      showUpdate: false,
      updateData: '',
      updateTextKey: '',
      infoObj: {
        userName: '昵称',
        phoneNumber: '手机号',
        eMail: '邮箱',
        userSex: '性别',
        userAgeTime: '生日'
      },
      commit: '去认证',
      userAgeTime: new Date(),
      minDate: new Date(1940, 0, 1),
      maxDate: new Date()
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    this.userCode = getUserInfo().userCode
    const userInfo = this.$cookie.get('userInfoDetails')
    if (userInfo) {
      this.userInfo = userInfo
    }
    this.getUserInfo()
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryAuthenticate()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 查询是否已经认证
    queryAuthenticate () {
      this.$authenticateSelf.getIdIdentifyRecord({}).then(res => {
        if (res.resultEntity !== null && res.resultEntity !== undefined) {
          this.commit = '已认证'
        }
      })
    },
    // 换绑手机号
    handleClickChangePhone () {
      this.$router.push({ name: 'selfChangePhone', query: { phoneNumber: this.userInfo.phoneNumber } })
    },
    // 获取个人信息
    getUserInfo () {
      this.$selfInfo.getUserInfo({}).then(res => {
        this.userInfo = res.resultEntity
        this.$cookie.set('userInfoDetails', this.userInfo)
      })
    },
    // 点击修改个人信息
    updateUserInfo (data) {
      this.updateTextKey = data
      this.showUpdate = true
    },
    // 修改确按钮
    updateInfoConfirm () {
      if (this.verify(this.updateTextKey, this.updateData)) {
        this.showUpdate = false
        const info = {
          userCode: this.userInfo.userCode,
          [this.updateTextKey]: this.updateData
        }
        console.log('updateInfoConfirm', info)
        this.$selfInfo.changeUserInfo(info).then(res => {
          console.log('res', res)
          this.getUserInfo()
          this.$toast.success('修改成功')
        }).catch(() => {
          this.$toast.fail('操作失败')
        })
        this.updateData = ''
      }
    },
    // 修改校验
    verify (type, data) {
      if (!data) {
        this.$toast(`${this.infoObj[type]}不能为空`)
        return false
      }
      switch (type) {
        case 'userName':
          if (data.length > 8) {
            this.$toast(`${this.infoObj[type]}不超过8个字`)
            return false
          }
          break
        case 'phoneNumber':
          var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
          if (!myreg.test(data)) {
            this.$toast(`请输入正确的${this.infoObj[type]}`)
            return false
          }
          break
        case 'eMail':
          var reg = new RegExp('^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$')
          if (!reg.test(data)) {
            this.$toast(`请输入正确的${this.infoObj[type]}`)
            return false
          }
          break
      }
      return true
    },
    // 上传图片
    uploadAvatar (file) {
      console.log('file', file)
      // 压缩图片
      const self = this
      /* eslint-disable-next-line no-new */
      new Compressor(file.file, {
        quality: 0.2,
        success (result) {
          // console.log("res", result);
          // 将 Blob 对象转换成 File 对象
          result = new window.File([result], result.name, { type: result.type })
          const formData = new FormData()
          formData.append('file', result)
          self.$selfInfo.uploadAvatar(formData).then(res => {
            console.log('上传成功', res)
            const avatar = res.resultEntity
            const info = {
              userCode: self.userCode,
              avatar
            }
            self.$selfInfo.changeUserInfo(info).then(res => {
              self.getUserInfo()
              self.$toast.success('修改成功')
            }).catch(() => {
              self.$toast.fail('操作失败')
            })
          }).catch(err => {
            console.log('头像上传失败', err)
          })
        },
        error (err) {
          console.log('压缩失败', err)
        }
      })
    },
    // 点击认证
    handleAttestation (val) {
      if (this.commit !== '已认证') {
        this.$router.push('/authenticateSelf')
      }
      // this.$toast('功能开发中')
    },
    // 注销登录
    reLogin () {
      this.$dialog.confirm({
        title: '注销',
        message: '是否退出登录'
      }).then(() => {
        if (window.android) {
          window.android.reLogin()
        } else if (window.webkit) {
          window.webkit.messageHandlers.reLogin.postMessage('')
        }
      }).catch(() => { })
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 149px;
  background-color: #ffffff;
  .img-box {
    position: relative;
    width: 80px;
    height: 80px;
    .head {
      position: absolute;
      left: 0;
      top: 0;
    }
    .camera {
      position: absolute;
      width: 23px;
      height: 23px;
      right: 0px;
      bottom: 0px;
    }
  }
}
.dt-secondLine {
  margin-top: 12px;
  height: 191.8px;
  background-color: #ffffff;
  .dt-line {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
    padding-left: 17.5px;
  }
  .dt-contc {
    text-align: right;
    padding-right: 12.5px;
    color: #666666;
  }
  .img {
    margin-top: 16.5px;
  }
}
.dt-thirdLine {
  margin-top: 12px;
  height: 95.9px;
  background-color: #ffffff;
  .dt-line {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
    padding-left: 17.5px;
  }
  .dt-contc {
    text-align: right;
    padding-right: 12.5px;
    color: #666666;
  }
  .img {
    margin-top: 16.5px;
  }
}
.dt-cut {
  width: 96%;
  height: 1px;
  margin-left: 2%;
  background-color: #f1f3f7;
}
.dt-attestation {
  display: flex;
  justify-content: flex-end;
  .dt-name {
    margin-right: 8px;
  }
  .dt-attestation-box {
    position: relative;
    margin-top: 5px;
    width: 60px;
    height: 21px;
    .dt-beijing {
      position: absolute;
      right: 0;
      top: 0;
    }
    .dt-dunpai {
      position: absolute;
      width: 13.5px;
      height: 16px;
      left: 5px;
      top: 10px;
    }
    .dt-text {
      position: absolute;
      width: 36px;
      height: 10px;
      left: 23px;
      top: 10.5px;

      font-size: 11px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #feffff;
      line-height: 16px;
    }
  }
}
.reLogin-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 30px;
  .reLogin {
    width: 94%;
    height: 100%;
    background: #19a9fc;
    border-radius: 5px;
    font-size: 17px;
    line-height: 40px;
    font-weight: Regular;
    text-align: center;
    color: #ffffff;
  }
}
</style>
